import { Bootstrap } from 'Bootstrap/Bootstrap';
import { Config } from "../../Lib/Config";
import loadGoogleMapsApi from 'load-google-maps-api';

// Icon
import markerIcon from '../../img/frontend/icons/place.svg';

let pageConfig = Config.defaults({
    lat: 52.719500,
    lng: 6.451630
});

class Contact extends Bootstrap {

    DOMReady() {

        loadGoogleMapsApi({
            key: 'AIzaSyB3uHQxDT0vogwC2NOw4PTqr_jmyegHpo0',
        }).then(function (googleMaps) {
            const map = new googleMaps.Map(document.getElementById('contactmap'), {
                center: {lat: pageConfig.lat, lng: pageConfig.lng},
                zoom: 18,
                disableDefaultUI: true
            });

            var icon = {
                url: markerIcon, // url
                scaledSize: new google.maps.Size(60, 60), // scaled size
            };
            let marker = new google.maps.Marker({
                position: {lat: pageConfig.lat, lng: pageConfig.lng},
                map: map,
                icon: icon
            });


            const mapDesktop = new googleMaps.Map(document.getElementById('contactmapdesktop'), {
                center: {lat: pageConfig.lat, lng: pageConfig.lng},
                zoom: 18,
                disableDefaultUI: true
            });

            var iconDesktop = {
                url: markerIcon, // url
                scaledSize: new google.maps.Size(60, 60), // scaled size
            };
            let markerDesktop = new google.maps.Marker({
                position: {lat: pageConfig.lat, lng: pageConfig.lng},
                map: mapDesktop,
                icon: icon
            });


        }).catch(function (error) {
            console.error(error)
        });
    }
}

new Contact();
