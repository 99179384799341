import {Bootstrap} from 'Bootstrap/Bootstrap';

class Video extends Bootstrap {

    DOMReady() {

        setTimeout(() => {
            $('html,body').animate({
                scrollTop: $('#video').offset().top - 50
            }, 200);
        }, 550);

    }

}

new Video();
