/**
 * Util
 *
 */
export let Util = {
    isTouchDevice: (function () {
        try {
            document.createEvent("TouchEvent");
            return true;
        } catch (e) {
            return false;
        }
    })()
};

