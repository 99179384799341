import {Bootstrap} from 'Bootstrap/Bootstrap';

// All php loaded images go here

// General images
import '../img/frontend/logo-corpoflow-white.svg';
import '../img/frontend/logo-corpoflow-black.svg';
import '../img/frontend/logo-corpoflow-color.svg';
import '../img/frontend/video-browser.svg';
import '../img/frontend/video-browser.png';
import '../img/frontend/play-button.svg';
import '../img/frontend/iphone-blank.svg';
import '../img/frontend/iphone-blank.png';
import '../img/frontend/iphone-straight.svg';
import '../img/frontend/iphone-straight.png';
import '../img/frontend/ipad-blank.svg';
import '../img/frontend/preview.png';

// Headers
import '../img/frontend/faq-header.svg';

// Icons
import '../img/frontend/icons/chat-bubble.svg';
import '../img/frontend/icons/plus.svg';
import '../img/frontend/icons/euro-house.svg';
import '../img/frontend/icons/bog.svg';
import '../img/frontend/icons/rent.svg';
import '../img/frontend/icons/terugkoop.svg';
import '../img/frontend/icons/student.svg';
import '../img/frontend/icons/park.svg';
import '../img/frontend/icons/cloud-check.svg';
import '../img/frontend/icons/circle-check-green.svg';
import '../img/frontend/icons/shield.svg';
import '../img/frontend/icons/cogs.svg';
import '../img/frontend/icons/task-board.svg';
import '../img/frontend/icons/phone.svg';
import '../img/frontend/icons/email.svg';
import '../img/frontend/icons/facebook-circle.svg';
import '../img/frontend/icons/instagram.svg';
import '../img/frontend/icons/youtube.svg';
import '../img/frontend/icons/twitter.svg';
import '../img/frontend/icons/linkedin.svg';
import '../img/frontend/icons/linkedin-small.svg';
import '../img/frontend/icons/phone-back.svg';
import '../img/frontend/icons/mail.svg';
import '../img/frontend/icons/close.svg';
import '../img/frontend/icons/place.svg';
import '../img/frontend/icons/email-blue.svg';
import '../img/frontend/icons/chart.svg';
import '../img/frontend/icons/share.svg';
import '../img/frontend/icons/happy.svg';

// People
import '../img/frontend/people/pascal-profile.png';
import '../img/frontend/people/diederik-profile.png';
import '../img/frontend/people/tom-profile.png';

// Logos
import '../img/frontend/logos/company-logo.svg';
import '../img/frontend/logos/funda.svg';
import '../img/frontend/logos/nvm.svg';
import '../img/frontend/logos/corpowonen.svg';
import '../img/frontend/logos/accolade.png';
import '../img/frontend/logos/elkien.png';
import '../img/frontend/logos/woonconcept.png';
import '../img/frontend/logos/wonen-wateringen.png';

// Integrations
//import '../img/frontend/integrations/corpowonen.svg';
import '../img/frontend/integrations/data-rotonde.png';
import '../img/frontend/integrations/datachecker.svg';
import '../img/frontend/integrations/facebook.svg';
import '../img/frontend/integrations/funda-white.svg';
import '../img/frontend/integrations/funda-in-business.png';
import '../img/frontend/integrations/huislijn.png';
import '../img/frontend/integrations/corpobog.svg';
import '../img/frontend/integrations/huur-koop-woningen.png';
import '../img/frontend/integrations/idin.png';
import '../img/frontend/integrations/jaap.nl.png';
import '../img/frontend/integrations/nwwi.png';
import '../img/frontend/integrations/onderteken.nl.png';
import '../img/frontend/integrations/pararius.svg';
import '../img/frontend/integrations/media.svg';
import '../img/frontend/integrations/taxateursunie.png';
import '../img/frontend/integrations/tvi.png';
import '../img/frontend/integrations/yesco.png';

// Jobs
import '../img/frontend/jobs/jobs_detail/bullet_point.svg';
import '../img/frontend/jobs/jobs_detail/curly_line.svg';
import '../img/frontend/jobs/php_medior.jpg';
import '../img/frontend/jobs/front_end.jpg';
import '../img/frontend/jobs/commercieel.jpg';
import '../img/frontend/jobs/open_vacature.jpg';
import '../img/frontend/jobs/lasergame.gif';
import '../img/frontend/jobs/jobs_detail/job_anna_v2.png';
import '../img/frontend/jobs/jobs_detail/job_developer_v2.png';
import '../img/frontend/jobs/jobs_detail/job_front_dev.png';
import '../img/frontend/jobs/jobs_detail/icons/feesten.svg';
import '../img/frontend/jobs/jobs_detail/icons/werkplek.svg';
import '../img/frontend/jobs/jobs_detail/icons/kantoorgenoten.svg';

// Job detail logos
import '../img/frontend/jobs/jobs_detail/logos/php.png';
import '../img/frontend/jobs/jobs_detail/logos/vue.png';
import '../img/frontend/jobs/jobs_detail/logos/javascript.png';
import '../img/frontend/jobs/jobs_detail/logos/laravel.png';
import '../img/frontend/jobs/jobs_detail/logos/cakephp.png';


// Misc
import '../img/frontend/blog_detail.jpg';
import '../img/frontend/dummy.jpg';

class Default extends Bootstrap {

}

new Default();
