import {Bootstrap} from 'Bootstrap/Bootstrap';

class Qr extends Bootstrap {

    DOMReady() {

    }

}

new Qr();
