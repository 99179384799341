import {Bootstrap} from 'Bootstrap/Bootstrap';

new (class extends Bootstrap {

    DOMReady() {



    }
});
