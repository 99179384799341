import {Bootstrap} from 'Bootstrap/Bootstrap';
import easing from 'jquery.easing';

import {Util} from 'Lib/Util';
import 'jquery-visible';
import 'jquery-touchswipe';

// Team
import 'img/frontend/team/diederik.png';
import 'img/frontend/team/jeroen.png';
import 'img/frontend/team/marissa.png';
import 'img/frontend/team/milan.png';
import 'img/frontend/team/pascal.png';
import 'img/frontend/team/tom.png';
import 'img/frontend/team/wouter.png';
import 'img/frontend/team/celine.png';
import 'img/frontend/team/trix.png';
import 'img/frontend/team/xaveer.png';
import 'img/frontend/team/anna.png';
import 'img/frontend/team/vacature.png';
import 'img/frontend/team/bryan.png';

// Team vcard
import 'img/frontend/team/vcard/diederik-vcard.png';
import 'img/frontend/team/vcard/jeroen-vcard.png';
import 'img/frontend/team/vcard/marissa-vcard.png';
import 'img/frontend/team/vcard/milan-vcard.png';
import 'img/frontend/team/vcard/pascal-vcard.png';
import 'img/frontend/team/vcard/tom-vcard.png';
import 'img/frontend/team/vcard/wouter-vcard.png';
import 'img/frontend/team/vcard/bas-vcard.png';
import 'img/frontend/team/vcard/celine-vcard.png';
import 'img/frontend/team/vcard/trix-vcard.png';
import 'img/frontend/team/vcard/xaveer-vcard.png';
import 'img/frontend/team/vcard/anna-vcard.png';
import 'img/frontend/team/vcard/bryan-vcard.png';

// Team
import 'img/frontend/team/outlines/diederik.svg';
import 'img/frontend/team/outlines/jeroen.svg';
import 'img/frontend/team/outlines/marissa.svg';
import 'img/frontend/team/outlines/milan.svg';
import 'img/frontend/team/outlines/pascal.svg';
import 'img/frontend/team/outlines/tom.svg';
import 'img/frontend/team/outlines/wouter.svg';
import 'img/frontend/team/outlines/bas.svg';
import 'img/frontend/team/outlines/celine.svg';
import 'img/frontend/team/outlines/trix.svg';
import 'img/frontend/team/outlines/xaveer.svg';
import 'img/frontend/team/outlines/anna.svg';
import 'img/frontend/team/outlines/vacature.svg';
import 'img/frontend/team/outlines/bryan.svg';

class Team extends Bootstrap {

    DOMReady() {
        const self = this;
        this.frontLayer = $('#frontLayer');
        this.backLayer = $('#backLayer');
        this.leftMost = $('#leftMost');
        this.rightMost = $('#rightMost');
        this.windowEl = $(window);
        this.tooltipEl = $('#hover-tooltip');

        $(window).on('resize', () => {
            let leftSize = Math.abs(this.leftMost.get(0).getBoundingClientRect().left);
            let rightSize = Math.abs(this.rightMost.get(0).getBoundingClientRect().right);
            this.totalSize = leftSize + rightSize;
        }).triggerHandler('resize');

        this.setupMouseMovement();

        if (Util.isTouchDevice) {
            this.setupTouchMovement();
        }

        $(document).on('click', 'section.team-layers svg path', function(e) {
            e.preventDefault();
            let medewerker = $(this).closest('svg').attr('class');

            if (medewerker === 'vacature') {
                return window.location.href="/werken-bij/";
            }

            $('body').addClass('modal-open');
            let medewerkerModalContent = $('#modalContents').find('[data-medewerker-content="' + medewerker + '"]');
            $('#medewerker-modal').slideDown({duration: 1200, easing: 'easeOutElastic'}).find('.inner-content').empty().append(medewerkerModalContent.clone());
            self.tooltipEl.hide();
        });

    }

    setupMouseMovement() {
        const self = this;

        let mousemoveAllowed = true;

        $(document).on('mousemove', 'section.team-layers', function(e) {
            if (!mousemoveAllowed) {
                return;
            }
            // How much percentage is my mouse on the left side of the screen?
            let percentageInScreen = (e.pageX / (self.windowEl.width() / 2)) * 100;
            let moveLeft = false;
            if (percentageInScreen <= 100) {
                moveLeft = true;
                percentageInScreen = 100 - percentageInScreen;
            } else {
                percentageInScreen = percentageInScreen - 100;
            }

            percentageInScreen = Math.ceil(percentageInScreen);

            // So how much do we have to move? thats totalsize / percentageInScreen
            let moveAmount = Math.ceil(((self.totalSize / 2) / 100) * percentageInScreen) / 2;

            window.requestAnimationFrame(function() {
                if (moveLeft) {
                    self.backLayer.css({'transform': `translateX(${moveAmount * 1}px)`});
                    self.frontLayer.css({'transform': `translateX(${moveAmount * 1.3}px)`});
                } else {
                    self.backLayer.css({'transform': `translateX(${-moveAmount * 1}px)`});
                    self.frontLayer.css({'transform': `translateX(${-moveAmount * 1.3}px)`});
                }
            });
        });

        // This prevents mousemove events on tapping a medewerker
        $(document).on('touchstart', 'section.team-layers', () => {
            mousemoveAllowed = false;
        });

        // This prevents mousemove events on tapping a medewerker
        $(document).on('touchend', 'section.team-layers', () => {
            setTimeout(function() {
                mousemoveAllowed = true;
            }, 500);
        });

        $(document).on('mouseenter', 'section.team-layers svg path', function(e) {
            let medewerker = $(this).closest('svg').attr('class');
            let medewerkerTooltipContent = $('#tooltipContents').find('[data-medewerker-tooltip-content="' + medewerker + '"]');
            self.tooltipEl.empty().append(medewerkerTooltipContent.clone()).hide();
            $(this).trigger('mousemove');
        });

        $(document).on('mouseleave', 'section.team-layers svg path', function(e) {
            self.tooltipEl.hide();
        });

        $(document).on('mousemove', 'section.team-layers svg path', function(e) {
            // How much percentage is my mouse on the left side of the screen?
            let percentageInScreen = (e.pageX / (self.windowEl.width() / 2)) * 100;

            let top = e.pageY;
            let left = e.pageX;

            self.tooltipEl.show(0, function() {
                let width = $(this).width();

                if (percentageInScreen >= 100) {
                    left = left - width - 40;
                }
                $(this).css({
                    top: top + 20,
                    left: left + 20
                }).show(200);
            });
        });
    }

    setupTouchMovement() {
        const self = this;
        $('section.team-layers').swipe({
            swipe: function(event, direction, distance, duration, fingerCount, fingerData) {
                if (distance >= 50) {
                    if (direction === 'left') {
                        self.moveFromTouch('left', -(distance), true);
                    } else if (direction === 'right') {
                        self.moveFromTouch('right', -(distance), true);
                    }
                }
            },
            allowPageScroll: 'vertical'
        });
    }

    moveFromTouch(direction, amount) {
        let amountFront = (direction === 'left') ? (amount * 1.2) : (-amount * 1.2);
        let amountBack = (direction === 'left') ? amount : -amount;

        let currentLeft = parseInt(this.backLayer.css('left'));
        let maxMovement = this.totalSize / 2 - 200;

        let maxLeft = (direction === 'left') ? -maxMovement : maxMovement;
        if (direction === 'left' && currentLeft <= maxLeft) {
            return;
        }
        if (direction === 'right' && currentLeft >= maxMovement) {
            return;
        }

        window.requestAnimationFrame(() => {
            this.backLayer.css('left', '+=' + amountBack);
            this.frontLayer.css('left', '+=' + amountFront);
        });

    }
}

new Team();
