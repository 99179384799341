import iconPlusWhite from "../img/frontend/icons/plus-white.svg";
import iconMinusWhite from "../img/frontend/icons/minus-white.svg";

/**
 * Config
 *
 */
export let Faq = (function () {

    class Faq {
        init() {
            $(document).on('click', 'div.faq_icon, h3.faq_title', function () {
                let faq = $(this).closest('.faq');
                let isActive = faq.hasClass('faq--open');

                let image = faq.find('img');
                let faqText = faq.find('.faq_text');

                if (isActive) {
                    faqText.slideUp(100);
                    faq.removeClass('faq--open');
                    image.attr('src', iconPlusWhite);
                } else {
                    faqText.slideDown(100);
                    faq.addClass('faq--open');
                    image.attr('src', iconMinusWhite);
                }
            });

            $(document).on('change', '#faq_category', function () {
                let selectedFaqGroup = $(this).val();
                if (!selectedFaqGroup) {
                    return true;
                }
                $('html, body').animate({
                    scrollTop: $("h1[data-faq-group='" + selectedFaqGroup + "']").offset().top
                }, 1500);

            });
        }
    }

    return new Faq;
})();
