import {Bootstrap} from 'Bootstrap/Bootstrap';
import {Config} from 'Lib/Config';

let pageConfig = Config.defaults({
    ref: false
});

class Home extends Bootstrap {

    DOMReady() {

        if (pageConfig.ref) {
            if ($('section.top_menu .mobile_menu').is(':visible')) {
                $(document.body).addClass('offcanvas-open');
            }
        }

    }

}

new Home();
