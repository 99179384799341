import {Bootstrap} from 'Bootstrap/Bootstrap';

class JobsDetail extends Bootstrap {

    DOMReady() {

        $('.gif_trigger').on('mouseover', function() {
            $(this).next().css('transform', 'scale3d(1,1,1)');
            $(this).css('transform', 'scale3d(0,0,0)');
        });

        $('.benefits__gif').on('mouseout', function() {
            $(this).prev().css('transform', 'scale3d(1,1,1)');
            $(this).css('transform', 'scale3d(0,0,0)');
        });

        $('.job_detail__doubt--dev').on('click', function() {
            $('html, body').animate({
                scrollTop: $("#ApplicationJobsDetailForm").offset().top - 100
            }, 'slow');
        })

        if (history.scrollRestoration) {
            history.scrollRestoration = 'manual';
        } else {
            window.onbeforeunload = function () {
                window.scrollTo(0, 0);
            }
        }

        // Set --scroll css var for on scroll animations
        window.addEventListener('scroll', () => {
            document.body.style.setProperty('--scroll', window.scrollY / (document.body.offsetHeight - window.innerHeight));
        }, false);

        // Discover svg path length for animations
        // console.log(document.querySelector(".curly_confe path").getTotalLength());

    }

}

new JobsDetail();